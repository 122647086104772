import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    login(user) {
        const token = Buffer.from(`${user.username}:${user.password}`, 'utf8').toString('base64')
        return apiClient.post('users/login', user, {
            headers: { 
                'Authorization': `Basic ${token}`
            }
        })
    }
}

