<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader/>
    <Toast />
		<br/><br/><p style="text-align:center;">Enter your <b>Username</b> and <b>Password</b> to sign in to the <b>Jacinta Gallant</b> Content Management System.</p><br/><br/>

		<div class="sign-in" style="text-align:center;">
			<div class="row">
						<div class="body">
							<div class="row-fluid">
								<div class="span7">
									<div class="sign-in-form">
										
                    <label for="inputEmail3" class="logintextcolor col-sm-20 control-label">Username </label>
                    <br/>
                    <input class="login form-field" name="username" placeholder="Username" type="text" v-model.trim="username" autofocus/>
                    <br/><br/>
                    <label for="inputEmail3" class="logintextcolor col-sm-20 control-label">Password </label>
                    <br/>
                    <input class="login form-field" name="password" placeholder="Password" type="password" @keyup.enter="login" v-model.trim="password"/>
                    <br/><br/>
                    <button type="submit" class="btn-orange btn btn-default" @click="login">SIGN IN</button>
                    <br/><br/>
										
									</div>
								</div>
							</div>
						</div>
				
			
			</div>
		</div>
    <cmsFooter/>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

// Components
import cmsHeader from '../../components/Header.vue'
import cmsFooter from '../../components/cms/cmsFooter.vue'

// API Services
import UserServices from '../../services/UserServices.js'

export default {
  name: 'cmsLogin',
  components: {
    cmsHeader, 
    cmsFooter, 
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT
    const toast = useToast();
    const store = useStore();
    const router = useRouter();
    const username = ref('');
    const password = ref('');

    const login = () => {
      const user = {username: username.value, password: password.value}
      UserServices.login(user)
      .then((response) => {
        if(response.status === 200) {
          toast.add({severity: 'success', summary: 'Logged In', detail: 'Credentials Accepted.', life: 3000})
          store.dispatch('login', response.data).then(() => router.push({name: 'cmsHome'}))
        } else {
          toast.add({severity: 'success', summary: 'Login Failed', detail: response.statusText, life: 3000})
        }
      })
      .catch((error) => {
        if(error.response === undefined) {
          toast.add({severity: 'error', summary: 'Error', detail: 'Something went wrong!', life: 3000})
        } else {
          toast.add({severity: 'error', summary: 'Error', detail: error.response.data.reason, life: 3000})
        }
      })
    }

  return {
    environment,
    toast,
    username,
    password,
    login
  }
  },
}
</script>

<style lang="scss" scoped>

</style>